/** @jsx jsx */
import { graphql, Link } from "gatsby"
import { jsx, Styled } from "theme-ui"

import Card from "./Card"
import Category from "./Category"
import Img from "./Img"

export const fragment = graphql`
  fragment Posts on ContentfulBlogPost {
    categories
    excerpt
    featuredImage {
      fluid(maxWidth: 450, quality: 80) {
        ...GatsbyContentfulFluid
      }
      ...ImageFields
    }
    id
    postTitle
    slug
  }
`

export default function Posts({ posts }) {
  if (Array.isArray(posts) && posts.length) {
    return posts.map(post => (
      <Link
        key={post.id}
        sx={{ variant: "link", display: "inline-block" }}
        to={post.slug}
      >
        <Card>
          <Img image={post.featuredImage} />
          <Category categories={post.categories} />
          <Styled.h3
            dangerouslySetInnerHTML={{ __html: post.postTitle }}
            sx={{ mb: 5 }}
          />
          <p sx={{ variant: "text.large", color: "grey.dark" }}>
            {post.excerpt}
          </p>
        </Card>
      </Link>
    ))
  }

  return null
}
