module.exports = {
  SE: {
    SV_SE: "sv-SE",
    //EN_US: "en-US",
  },
  FI: {
    FI: "fi",
    //FI_FI: "fi-FI",
  },
  slug: {
    SE: {
      "sv-SE": "",
      //"en-US": "en",
    },
    FI: {
      fi: "",
      //"fi-FI": "sv",
    },
  },
}
