/** @jsx jsx */
import { Link as GatsbyLink } from "gatsby"
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

export default function Link({ children, slug }) {
  return (
    <GatsbyLink
      activeClassName="active"
      sx={{
        variant: "link",
        color: "text",
        display: "flex",
        fontSize: 0,
        justifyContent: "center",
        position: "relative",

        ":hover": {
          "::after": {
            width: "24px",
            visibility: "visible",
          },
        },

        "::after": {
          backgroundColor: "primary",
          bottom: "-14px",
          content: '""',
          height: "2px",
          left: "50%",
          position: "absolute",
          transform: "translateX(-50%)",
          transition: "width 300ms ease",
          visibility: "hidden",
          width: "0px",
        },

        "&.active": {
          "::after": {
            width: "24px",
            visibility: "visible",
          },
        },
      }}
      to={slug}
    >
      {children}
    </GatsbyLink>
  )
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  slug: PropTypes.string.isRequired,
}
