/** @jsx jsx */
import PropTypes from "prop-types"
import { Fragment } from "react"
import { Grid, jsx } from "theme-ui"

import Link from "./Link"

export default function Pagination({ pagination }) {
  if (pagination) {
    const { firstpage, lastpage, pages } = pagination

    const pagesNum = pages.map(page => page.page)
    const displayFirstpageLink = !pagesNum.includes(firstpage.page)
    const displayLastpageLink = !pagesNum.includes(lastpage.page)

    return (
      <Grid
        as="div"
        columns="repeat(auto-fit, 24px)"
        gap={[6, 10]}
        sx={{
          justifyContent: "center",
          gridColumn: "1 / -1",
        }}
      >
        {displayFirstpageLink ? (
          <Fragment>
            <Link slug={firstpage.slug}>{firstpage.page}</Link>
            <span>...</span>
          </Fragment>
        ) : null}
        {pages.map(({ page, slug }) => (
          <Link key={slug} slug={slug}>
            {page}
          </Link>
        ))}
        {displayLastpageLink ? (
          <Fragment>
            <span>...</span>
            <Link slug={lastpage.slug}>{lastpage.page}</Link>
          </Fragment>
        ) : null}
      </Grid>
    )
  }

  return null
}

Pagination.defaultProps = {
  pagination: null,
}

Pagination.propTypes = {
  pagination: PropTypes.object,
}
