/** @jsx jsx */
import SimpleHero from "@components/blocks/SimpleHero"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { Fragment } from "react"
import { Grid, jsx } from "theme-ui"

import NewsLayout from "@layout/NewsLayout"

import Posts from "./Posts"

// export const fragment = graphql`
//   fragment Newspage on ContentfulNewspage {
//     seotags {
//       ...Seo
//     }
//     title
//   }
// `

export const fragment = graphql`
  fragment Newspage on ContentfulNewspage {
    title
  }
`

export default function Newspage({ pagination, posts, title }) {
  return (
    <Fragment>
      <SimpleHero title={title} />
      <NewsLayout
        posts={posts}
        pagination={pagination}
        variants={["container", "containerSpacing"]}
      >
        <Grid gap={[8, 12]}>
          <Posts posts={posts} />
        </Grid>
      </NewsLayout>
    </Fragment>
  )
}

Newspage.defaultProps = {
  pagination: null,
}

Newspage.propTypes = {
  pagination: PropTypes.object,
  posts: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
}
