/** @jsx jsx */
import locales from "@constants/locales"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import { useMemo } from "react"
import { jsx, useThemeUI } from "theme-ui"
import List from "@components/Newspage/List"
import Pagination from "@components/Newspage/Pagination"
import Variant from "@components/Variant"
import useLocaleContext from "@hooks/useLocaleContext"

export const fragment = graphql`
  fragment NewsLayout on ContentfulNewspage {
    categoryTitle
    links {
      href
      id
      slug
      title
    }
    linksTitle
    node_locale
    slug
  }
`

export default function NewsLayout({ children, variants, pagination, posts }) {
  const {
    theme: { space },
  } = useThemeUI()
  const data = useStaticQuery(
    graphql`
      {
        categories: allCategory(sort: { fields: name, order: ASC }) {
          nodes {
            ...CategoryList
          }
        }
        newspages: allContentfulNewspage {
          nodes {
            ...NewsLayout
          }
        }
        posts: allContentfulBlogPost {
          totalCount
        }
      }
    `
  )
  const locale = useLocaleContext()

  const categoryAllMap = {
    fi: "Kaikki",
    "fi-FI": "Alla",
    "sv-SE": "Alla",
    "sv-EN": "All",
  }

  const [categories, newspage] = useMemo(() => {
    const n = data.newspages.nodes.find(np => np.node_locale === locale)

    const c = data.categories.nodes.filter(cat => cat.node_locale === locale)

    c.push({
      count: pagination?.numberOfItems,
      id: "PWopdja394",
      name: categoryAllMap[locale],
      slug: n.slug,
    })

    return [c, n]
  }, [
    data.categories.nodes,
    data.newspages.nodes,
    data.posts.totalCount,
    locale,
  ])

  return (
    <Variant
      as="section"
      columns={["1fr", "1fr auto", "1fr 300px"]}
      d="grid"
      gap={[
        13,
        `${space[10]}px ${space[12]}px`,
        `${space[18]}px ${space[17]}px`,
      ]}
      sx={{ alignItems: "flex-start" }}
      variants={variants}
    >
      {children}
      <aside
        sx={{
          alignSelf: "flex-start",
          display: ["none", "grid"],
          gridTemplateColumns: "1fr",
          rowGap: 15,
        }}
      >
        <List
          totalCount={pagination?.numberOfItems}
          list={categories}
          title={newspage.categoryTitle}
        />
        <List list={newspage.links} title={newspage.linksTitle} />
      </aside>
      <Pagination pagination={pagination} />
    </Variant>
  )
}

NewsLayout.defaultProps = {
  pagination: null,
  variants: ["container"],
}

NewsLayout.propTypes = {
  children: PropTypes.node.isRequired,
  pagination: PropTypes.object,
  variants: PropTypes.array,
}
