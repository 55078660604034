/** @jsx jsx */
import useLocaleContext from "@src/hooks/useLocaleContext"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { jsx, Styled } from "theme-ui"

import Link from "./Link"

export const fragment = graphql`
  fragment CategoryList on Category {
    count
    id
    name
    node_locale
    slug
  }
`

const LOCALE_MAP = {
  "en-US": "/en",
  "fi-FI": "/sv",
}
export default function List({ list, title, totalCount }) {
  const locale = useLocaleContext()

  if (Array.isArray(list) && list.length) {
    return (
      <nav>
        <Styled.h3 sx={{ mb: 7 }}>{title}</Styled.h3>
        <Styled.ul>
          {list.map(link => {
            return (
              <Styled.li key={link.id} sx={{ "& + &": { mt: 6 } }}>
                <Link
                  href={link.href}
                  slug={`${LOCALE_MAP[locale] && link.slug ? LOCALE_MAP[locale] : ""}${
                    link.slug ? link.slug : ""
                  }`}
                >
                  {link.title || link.name}{" "}
                  {link.name ? `(${link.count})` : null}
                </Link>
              </Styled.li>
            )
          })}
        </Styled.ul>
      </nav>
    )
  }

  return null
}

List.defaultProps = {
  list: null,
  title: undefined,
}

List.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
}
