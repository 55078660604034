/** @jsx jsx */
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { jsx, Styled } from "theme-ui"

import Variant from "@components/Variant"

export const fragment = graphql`
  fragment ContentfulSimpleHeroBlock on ContentfulSimpleHeroBlock {
    id
    title
  }
`

export default function SimpleHero({ title }) {
  return (
    <Variant
      as="section"
      d="flex"
      sx={{
        alignItems: "center",
        flexDirection: "column",
        pt: [15, 18],
        px: 6,
      }}
      variant="container"
    >
      <Styled.h1
        sx={{
          mb: [6, null, 10],
          textAlign: "center",
          wordBreak: "break-word",
        }}
      >
        {title}
      </Styled.h1>
      <img alt="Underline" src="/imgs/underline.svg" sx={{ height: 6 }} />
    </Variant>
  )
}

SimpleHero.typeName = "ContentfulSimpleHeroBlock"

SimpleHero.propTypes = {
  title: PropTypes.string.isRequired,
}
