/** @jsx jsx */
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

export default function Category({ categories }) {
  if (Array.isArray(categories) && categories.length) {
    const category = categories[0]
    return (
      <span
        sx={{
          color: "primary",
          display: "inline-block",
          fontFamily: "body",
          fontSize: 3,
          fontWeight: "bold",
          mb: 5,
        }}
      >
        {category}
      </span>
    )
  }

  return null
}

Category.defaultProps = {
  categories: null,
}

Category.propTypes = {
  categories: PropTypes.array,
}
