/** @jsx jsx */
import { useLocation } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { jsx } from "theme-ui"

import useLocaleContext from "@hooks/useLocaleContext"

export const fragment = graphql`
  fragment Seo on SeoTag {
    content
    name
    property
    tag
  }
`

const query = graphql`
  query DefaultSeoQuery {
    allSettings: allContentfulSiteSettings {
      nodes {
        createdAt
        node_locale
        siteDescription {
          siteDescription
        }
        siteImage {
          file {
            details {
              image {
                height
                width
              }
            }
            url
          }
        }
        siteTitle
        twitterUsername
        updatedAt
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

export default function Seo({ seo }) {
  const { pathname } = useLocation()
  const locale = useLocaleContext()
  const { allSettings, site } = useStaticQuery(query)

  if (!Array.isArray(seo) || !seo.length) {
    const settings = allSettings.nodes.find(node => node.node_locale === locale)
    seo = [
      {
        content: settings?.siteTitle,
        tag: "title",
      },
      {
        content: settings?.siteTitle,
        property: "og:title",
        tag: "meta",
      },
      {
        content: settings?.siteTitle,
        name: "twitter:title",
        tag: "meta",
      },
      {
        content: settings?.siteDescription?.siteDescription,
        name: "description",
        tag: "meta",
      },
      {
        content: settings?.siteDescription?.siteDescription,
        property: "og:description",
        tag: "meta",
      },
      {
        content: settings?.siteDescription?.siteDescription,
        name: "twitter:description",
        tag: "meta",
      },
      {
        content: settings?.siteImage?.file?.url,
        property: "og:image",
        tag: "meta",
      },
      {
        content: settings?.siteImage?.file?.details?.image?.width,
        property: "og:image:width",
        tag: "meta",
      },
      {
        content: settings?.siteImage?.file?.details?.image?.height,
        property: "og:image:height",
        tag: "meta",
      },
      {
        content: settings?.siteImage?.file?.url,
        name: "twitter:image",
        tag: "meta",
      },
      {
        content: settings.node_locale,
        property: "og:locale",
        tag: "meta",
      },
      {
        content: "Web",
        property: "og:type",
        tag: "meta",
      },
      {
        content: settings.siteTitle,
        property: "og:site_name",
        tag: "meta",
      },
      {
        content: settings.createdAt,
        property: "article:published_time",
        tag: "meta",
      },
      {
        content: settings.updatedAt,
        property: "article:modified_time",
        tag: "meta",
      },
      {
        content: "Brocc",
        property: "article:author",
        tag: "meta",
      },
      {
        content: "summary_large_image",
        name: "twitter:card",
        tag: "meta",
      },
      {
        content: settings?.twitterUsername,
        name: "twitter:creator",
        tag: "meta",
      },
    ]
  }

  return (
    <Helmet>
      <meta
        content={`${site?.siteMetadata?.siteUrl}${pathname}`}
        property="og:url"
      />
      {Array.isArray(seo)
        ? seo.map(({ content, name, property, tag: Tag }) =>
            Tag === "title" ? (
              <Tag content={content} key={content}>
                {content}
              </Tag>
            ) : (
              <Tag
                content={content}
                key={content + name + property}
                {...{ [name ? "name" : "property"]: name || property }}
              />
            )
          )
        : null}
    </Helmet>
  )
}

Seo.defaultProps = {
  seo: null,
}

Seo.propTypes = {
  seo: PropTypes.array,
}
