import { graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { Fragment } from "react"

import Newspage from "@components/Newspage"
import Seo from "@components/Seo"

export default function NewspageTemplate({
  data: { newspage, posts },
  pageContext: { pagination },
}) {
  return (
    <Fragment>
      {/* <Seo seo={newspage.seotags} /> */}
      <Newspage
        pagination={pagination}
        posts={posts?.nodes}
        title={newspage.title}
      />
    </Fragment>
  )
}

export const query = graphql`
  query getNewspage(
    $contentful_id: String!
    $ids: [String]!
    $locale: String!
  ) {
    newspage: contentfulNewspage(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $locale }
    ) {
      ...Newspage
    }
    posts: allContentfulBlogPost(
      filter: { node_locale: { eq: $locale }, id: { in: $ids } }
    ) {
      nodes {
        ...Posts
      }
    }
  }
`

NewspageTemplate.propTypes = {
  data: PropTypes.shape().isRequired,
  pageContext: PropTypes.object.isRequired,
}
