/** @jsx jsx */
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

export default function Card({ children }) {
  return (
    <article
      sx={{
        backgroundColor: "grey.lighter",
        borderRadius: "20px",
        height: "100%",
        padding: [6, 12],
      }}
    >
      {children}
    </article>
  )
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
}
