/** @jsx jsx */
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

import Image from "@components/Image"

export default function Img({ image }) {
  if (image) {
    return (
      <div
        sx={{
          maxHeight: 300,
          mb: 10,
          overflow: "hidden",
        }}
      >
        <Image {...image} />
      </div>
    )
  }

  return null
}

Img.defaultProps = {
  image: null,
}

Img.propTypes = {
  image: PropTypes.object,
}
